import "bootstrap/dist/css/bootstrap.min.css";
import { getUnixTime } from "date-fns";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import * as api from "../api/api";
import { DateRange } from "../common/DateRange";
import { TransactionType } from "../types/TransactionType";
import { getFormattedDateTime } from "../util/getFormattedDateTime";
import { Link } from "react-router-dom";


// ***************************************************************************************************
// ***************************************************************************************************
export function Transactions( props: any )
{
	const [ userId, setUserId ] = useState<number | null>( props?.userId || null );
	const [ transactions, setTransactions ] = useState<TransactionType[]>( [] );

	const [ sourceTransactionIdFilter, setSourceTransactionIdFilter ] = useState<string>( "" );
	const [ sourceFilter, setSourceFilter ] = useState<number | null>( null );
	const [ dateFromFilter, setDateFromFilter ] = useState<Date | null>( null );
	const [ dateToFilter, setDateToFilter ] = useState<Date | null>( null );

	useEffect( () => { updateTransactions() }, [] );

	const updateTransactions = async () =>
	{
		const filters =
		{
			userId,
			sourceId: sourceFilter,
			sourceTransactionId: sourceTransactionIdFilter,
			dateFromTimestamp: dateFromFilter && getUnixTime( dateFromFilter ),
			dateToTimestamp: dateToFilter && getUnixTime( dateToFilter )
		};

		const transactionsList = await api.getTransactions( filters );
		setTransactions( transactionsList );
	}


	// ***************************************************************************************************
	// ***************************************************************************************************
	const onSourceTransactionIdUpdate = ( event: any ) => setSourceTransactionIdFilter( event.target.value );
	const onSourceTransactionIdKeyPress = ( event: any ) =>
		{
			if (event.key === "Enter")
				event.preventDefault();
		}
	const onSourceFilterChange = ( event: any ) => setSourceFilter( +event.target.value || null );
	const resetFilter = () =>
	{
		setSourceTransactionIdFilter( "" );
		setSourceFilter( null );
		setDateFromFilter( null );
		setDateToFilter( null );
	};

	return (
		<>
			<Row md={ 12 }>
				<Col md={ 2 }>
					<Form.Group as={ Col }>
						<Form.Label>ID транзакции</Form.Label>
						<Form.Control
							type="text"
							value={ sourceTransactionIdFilter }
							onChange={ onSourceTransactionIdUpdate }
							onKeyPress={ onSourceTransactionIdKeyPress } />
					</Form.Group>
				</Col>

				<Col md={ 3 }>
					<Form.Group as={ Col }>
						<Form.Label>Источник</Form.Label>
						<Form.Select value={ sourceFilter || "" } onChange={ onSourceFilterChange }>
							<option value={ "" }></option>
							<option value={ 1 }>Действие пользователя</option>
							<option value={ 2 }>Действие Администратора</option>
							<option value={ 3 }>Google</option>
							<option value={ 4 }>Apple</option>
						</Form.Select>
					</Form.Group>
				</Col>

				<DateRange
					dateFrom={ dateFromFilter }
					dateTo={ dateToFilter }
					onDateFromChange={ setDateFromFilter }
					onDateToChange={ setDateToFilter } />

				<Col md={ 2 }>
					<Button variant="danger" onClick={ resetFilter } style={{marginTop: 32}}>
						Сбросить фильтр
					</Button>
				</Col>

				<Col md={ 2 }>
					<Button variant="primary" onClick={ updateTransactions } style={{marginTop: 32}}>
						Найти
					</Button>
				</Col>
			</Row>

			<Row>
				<Table striped bordered hover className="marginTop">
					<thead>
						<tr>
							<th>ID</th>
							<th>Дата</th>
							<th>Источник</th>
							<th>Пользователь</th>
							<th>Баланс до</th>
							<th>Сумма</th>
							<th>Баланс после</th>
							<th>ID транзакции</th>
							<th>Описание</th>
						</tr>
					</thead>
					<tbody>
						{
							transactions.map( ( transaction ) =>
								<tr key={ transaction.id }>
									<td>{ transaction.id }</td>
									<td>{ getFormattedDateTime( transaction.date ) }</td>
									<td>{ getSourceTitle( transaction.sourceId ) }</td>
									<td><Link to={ "/users/" + transaction.userId }>{ transaction.userId }</Link></td>
									<td>{ transaction.balanceBefore }</td>
									<td>{ transaction.amount }</td>
									<td>{ transaction.balanceAfter }</td>
									<td>{ transaction.sourceTransactionId }</td>
									<td>{ transaction.description }</td>
								</tr>
							)
						}
					</tbody>
				</Table>
			</Row>
		</>
	);
}

// ***************************************************************************************************
// ***************************************************************************************************
function getSourceTitle( sourceId: number ): string
{
	if( sourceId === 1 )
		return "Действие пользователя";

	if( sourceId === 2 )
		return "Действие Администратора";

	if( sourceId === 3 )
		return "Google";

	if( sourceId === 4 )
		return "Apple";

	return "Неизвестный источник";
}
