import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import * as api from "../../../api/api";
import { ServiceEntryType } from "../../../types/ServiceEntryType";
import { FormControlString } from "../../../util/FormControlString";


interface ServiceEditModalProps
{
	service: ServiceEntryType | null,
	onClose: () => void,
	onSave: () => void
}


// ***************************************************************************************************
// ***************************************************************************************************
export function ServiceEditModal( props: ServiceEditModalProps )
{
	const { service, onClose } = props;
	const [ title, setTitle ] = useState<string>( service?.title || "" );
	const [ smsActivateCode, setSmsActivateCode ] = useState<string>( service?.smsActivateCode || "" );
	const [ fiveSimCode, setFiveSimCode ] = useState<string>( service?.fiveSimCode || "" );
	const [ onlineSimCode, setOnlineSimCode ] = useState<string>( service?.onlineSimCode || "" );
	const [ icon, setIcon ] = useState<string>( service?.icon || "" );
	const [ showLoading, setShowLoading ] = useState<boolean>( false );

	const isSaveButtonDisabled = !title || showLoading;


	// ***************************************************************************************************
	// ***************************************************************************************************
	const onSave = async () =>
	{
		if( isSaveButtonDisabled )
		 	return;

		try
		{
			const newService: ServiceEntryType =
			{
				id: service?.id || 0,
				title,
				smsActivateCode,
				fiveSimCode,
				onlineSimCode,
				icon
			};

			setShowLoading( true );

			await api.saveService( newService );
			props.onSave();
		}
		catch( error )
		{
			console.log( "Ошибка записи сервиса:", error );
			alert( "Ошибка записи сервиса! Попробуйте повторить попытку позже." );
		}
		finally
		{
			setShowLoading( false );
		}
	};


	// ***************************************************************************************************
	// ***************************************************************************************************
	return (
		<Modal show={ true } onHide={ onClose }>
			<Modal.Header closeButton>
				<Modal.Title>Редактирование сервиса</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>Наименование</Form.Label>
						<FormControlString placeholder="Наименование" value={ title } onChange={ setTitle } />
					</Form.Group>
					<Form.Group>
						<Form.Label>Код SMS-activate</Form.Label>
						<FormControlString placeholder="Код SMS-activate" value={ smsActivateCode } onChange={ setSmsActivateCode } />
					</Form.Group>
					<Form.Group>
						<Form.Label>Код 5SIM</Form.Label>
						<FormControlString placeholder="Код 5SIM" value={ fiveSimCode } onChange={ setFiveSimCode } />
					</Form.Group>
					<Form.Group>
						<Form.Label>Код onlineSIM</Form.Label>
						<FormControlString placeholder="Код onlineSIM" value={ onlineSimCode } onChange={ setOnlineSimCode } />
					</Form.Group>
					<Form.Group>
						<Form.Label>Файл иконки</Form.Label>
						<FormControlString placeholder="Файл иконки" value={ icon } onChange={ setIcon } />
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={ onClose }>
					Отмена
				</Button>
				<Button variant="primary" onClick={ onSave } disabled={ isSaveButtonDisabled }>
					Записать
					{ showLoading && <Spinner animation="border" variant="warning" /> }
				</Button>
			</Modal.Footer>
		</Modal>
	);

}
