import React from "react";
import { Form } from "react-bootstrap";



// ***************************************************************************************************
// ***************************************************************************************************
export function FormControlString( props: any )
{
	const { value, onChange, placeholder } = props;

	const onValueChange = ( event: any ) => onChange( event.target.value );

	return (
		<Form.Control
			type="string"
			placeholder={ placeholder }
			value={ value }
			onChange={ onValueChange } />
	);
}
