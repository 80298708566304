import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Col, Row, Spinner, Table } from "react-bootstrap";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Link } from "react-router-dom";
import { RefundType } from "src/types/RefundType";
import * as api from "../api/api";



export function Refunds()
{
	const [ refunds, setRefunds ] = useState<RefundType[]>( [] );
	const [ loading, setLoading ] = useState<boolean>( false );

	useEffect( () =>
	{
		updateRefunds();
	}, [] );


	const updateRefunds = async () =>
	{
		setLoading( true );

		try
		{
			const productsList = await api.getRefunds();
			setRefunds( productsList );
		}
		catch( error )
		{
			console.error( error );
		}

		setLoading( false );
	}


	// ***************************************************************************************************
	// ***************************************************************************************************
	return (
		<>
			<ResultTable refunds={ refunds } loading={ loading } />
		</>
	);
}


// ***************************************************************************************************
// ***************************************************************************************************
interface ResultTableProps
{
	refunds: RefundType[];
	loading: boolean;
}


// ***************************************************************************************************
// ***************************************************************************************************
function ResultTable( { refunds, loading }: ResultTableProps )
{
	if( loading )
		return <Row><Col><Spinner animation="border" /></Col></Row>;

	if( !refunds.length )
		return <Row><Col>Нет данных для вывода</Col></Row>;

	return (
		<Row>
			<Table striped bordered hover>
				<thead>
					<tr>
						<th>ID транзакции</th>
						<th>Дата GMT</th>
						<th>Пользователь</th>
						<th>Обработано</th>
					</tr>
				</thead>
				<tbody>
					{
						refunds.map( ( refund ) =>
							<RefundRow refund={ refund } />
						)
					}
				</tbody>
			</Table>
		</Row>
	);
}


interface TransactionRowProps
{
	refund: RefundType
}


// ***************************************************************************************************
// ***************************************************************************************************
function RefundRow( props: TransactionRowProps )
{
	const { refund } = props;

	return (
		<>
			<tr key={ refund.sourceTransactionId }>
				<td>{ refund.sourceTransactionId }</td>
				<td>{ refund.date }</td>
				<td>{ getUser( refund.userId ) }</td>
				<td>{ refund.refundProcessed ? "Да" : "Нет" }</td>
			</tr>
		</>
	);
}



// ***************************************************************************************************
// ***************************************************************************************************
function getUser( userId: number | null )
{
	if( !userId )
		return "Unknown";

	return <Link to={ "/users/" + userId }>{ userId }</Link>;
}
