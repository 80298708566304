

export enum OrderStatuses
{
	PHONE_NUMBER_RENTED = 0,
	WAITING_FOR_SMS = 1,
	SMS_RECEIVED = 2,
	PHONE_NUMBER_RELEASED = 3,
	RENT_CANCELLED = 4
}
