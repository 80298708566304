import React from "react";
import { Form } from "react-bootstrap";


// ***************************************************************************************************
// ***************************************************************************************************
export function FormControlNumber( props: any )
{
	const { value, onChange, placeholder } = props;

	const onValueChange = ( event: any ) => onChange( +event.target.value || null );

	return (
		<Form.Control
			type="number"
			placeholder={ placeholder }
			value={ value }
			onChange={ onValueChange } />
	);
}
