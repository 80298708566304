import "bootstrap/dist/css/bootstrap.min.css";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as api from "../../api/api";
import { CountryEntryType } from "../../types/CountryEntryType";
import { ProviderType } from "src/types/ProviderType";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';



// ***************************************************************************************************
// ***************************************************************************************************
export function Providers()
{
	const [ providers, setProviders ] = useState<ProviderType[]>( [] );
	const [ showLoading, setShowLoading ] = useState<boolean>( false );

	useEffect( () => { updateProviders(); }, [] );


	// ***************************************************************************************************
	// ***************************************************************************************************
	const updateProviders = async () =>
	{
		const countriesList = await api.getProvidersList();
		setProviders( countriesList );
	};


	// ***************************************************************************************************
	// ***************************************************************************************************
	const onDragEnd = ( result: any ) =>
	{
		if( !result.destination )
		{
			return;
		}

		const updatedProviders = [ ...providers ];
		const [ reorderedProvider ] = updatedProviders.splice( result.source.index, 1 );
		updatedProviders.splice( result.destination.index, 0, reorderedProvider );

		setProviders( updatedProviders.map( ( provider, index ) => ( { ...provider, priority: index + 1 } ) ) );
	};


	// ***************************************************************************************************
	// ***************************************************************************************************
	const onSave = async () =>
	{
		if( showLoading )
			return;

		try
		{
			setShowLoading( true );

			await api.updateProvidersPriority( providers );
		}
		catch( error )
		{
			console.log( "Ошибка записи приоритетов платформ:", error );
			alert( "Ошибка записи приоритетов платформ! Попробуйте повторить попытку позже." );
		}
		finally
		{
			setShowLoading( false );
		}
	};




	// ***************************************************************************************************
	// ***************************************************************************************************
	const isSaveButtonDisabled = showLoading;

	return (
		<Container>
			<Breadcrumb>
				<Breadcrumb.Item><Link to="/">Главная</Link></Breadcrumb.Item>
				<Breadcrumb.Item active>Платформы</Breadcrumb.Item>
			</Breadcrumb>

			<DragDropContext onDragEnd={ onDragEnd }>
				<Droppable droppableId="provider-list">
					{
						( provided ) =>
						(

							<Table striped bordered hover className="marginTop" { ...provided.droppableProps } ref={ provided.innerRef }>
								<thead>
									<tr>
										<th>ID</th>
										<th>Наименование</th>
										<th>Приоритет</th>
									</tr>
								</thead>
								<tbody>
									{
										providers.map( ( provider, index ) =>
											<Draggable key={ provider.id.toString() } draggableId={ provider.id.toString() } index={ index }>
												{
													( provided ) =>
													(
														<tr key={ provider.id } ref={ provided.innerRef } { ...provided.draggableProps } { ...provided.dragHandleProps }>
															<td>{ provider.id }</td>
															<td>{ provider.title }</td>
															<td>{ provider.priority }</td>
														</tr>
													)
												}
											</Draggable>
										)
									}
								</tbody>
							</Table>
						)
					}
				</Droppable>
			</DragDropContext>
			<Button variant="primary" onClick={ onSave } disabled={ isSaveButtonDisabled }>
				Записать
				{ showLoading && <Spinner animation="border" variant="warning" /> }
			</Button>
		</Container>
	);


	// return (
	// 	<DragDropContext onDragEnd={handleDragEnd}>
	// 	  <Droppable droppableId="provider-list">
	// 		{(provided) => (
	// 		  <Table striped bordered hover {...provided.droppableProps} ref={provided.innerRef}>
	// 			<thead>
	// 			  <tr>
	// 				<th>ID</th>
	// 				<th>Priority</th>
	// 				<th>Title</th>
	// 			  </tr>
	// 			</thead>
	// 			<tbody>
	// 			  {providers.map((provider, index) => (
	// 				<Draggable key={provider.id.toString()} draggableId={provider.id.toString()} index={index}>
	// 				  {(provided) => (
	// 					<tr
	// 					  ref={provided.innerRef}
	// 					  {...provided.draggableProps}
	// 					  {...provided.dragHandleProps}
	// 					>
	// 					  <td>{provider.id}</td>
	// 					  <td>{provider.priority}</td>
	// 					  <td>{provider.title}</td>
	// 					</tr>
	// 				  )}
	// 				</Draggable>
	// 			  ))}
	// 			</tbody>
	// 		  </Table>
	// 		)}
	// 	  </Droppable>
	// 	</DragDropContext>
	//   );
}








