import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import * as api from "../../api/api";
import { CountryEntryType } from "../../types/CountryEntryType";
import { PriceEntryType } from "../../types/PriceEntryType";
import { ServiceEntryType } from "../../types/ServiceEntryType";
import { FormControlNumber } from "../../util/FormControlNumber";


interface PricelistEditModalProps
{
	priceEntry: PriceEntryType | null,
	countries: CountryEntryType[],
	services: ServiceEntryType[],
	onClose: () => void,
	onSave: () => void
}


// ***************************************************************************************************
// ***************************************************************************************************
export function PricelistEditModal( props: PricelistEditModalProps )
{
	const { priceEntry, countries, services, onClose } = props;

	const [ countryId, setCountryId ] = useState<number | undefined>( priceEntry?.countryId );
	const [ serviceId, setServiceId ] = useState<number | undefined>( priceEntry?.serviceId );
	const [ cost, setCost ] = useState<number | undefined>( priceEntry?.cost );
	const [ isDisabled, setIsDisabled ] = useState<boolean>( priceEntry?.isDisabled || false );
	const [ showLoading, setShowLoading ] = useState<boolean>( false );


	// ***************************************************************************************************
	// ***************************************************************************************************
	const onCountryChange = ( event: any ) => setCountryId( +event.target.value || undefined );
	const onServiceChange = ( event: any ) => setServiceId( +event.target.value || undefined );
	const onIsDisabledChange = () => setIsDisabled( !isDisabled );


	// ***************************************************************************************************
	// ***************************************************************************************************
	const onSave = async () =>
	{
		if( isSaveButtonDisabled || !countryId || !serviceId || !cost || cost < 0 )
		 	return;

		try
		{
			const newPricelistEntry: PriceEntryType =
			{
				id: priceEntry?.id || 0,
				countryId,
				serviceId,
				cost,
				isDisabled
			};

			setShowLoading( true );

			await api.savePricelist( newPricelistEntry );
			props.onSave();
		}
		catch( error )
		{
			console.log( "Ошибка записи сервиса:", error );
			alert( "Ошибка записи сервиса! Попробуйте повторить попытку позже." );
		}
		finally
		{
			setShowLoading( false );
		}
	};


	// ***************************************************************************************************
	// ***************************************************************************************************
	const isSaveButtonDisabled = !(countryId && serviceId && cost && cost > 0) || showLoading;

	return (
		<Modal show={ true } onHide={ onClose }>
			<Modal.Header closeButton>
				<Modal.Title>Редактирование прайс-листа</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>Страна</Form.Label>
						<Form.Select value={ countryId } onChange={ onCountryChange } disabled={ !!priceEntry?.countryId }>
							<option>Выберите страну</option>
							{
								countries.map( ( element ) =>
									<option key={ element.id } value={ element.id }>{ element.title }</option>
								)
							}
						</Form.Select>
					</Form.Group>
					<Form.Group>
						<Form.Label>Сервис</Form.Label>
						<Form.Select value={ serviceId } onChange={ onServiceChange } disabled={ !!priceEntry?.serviceId }>
							<option>Выберите сервис</option>
							{
								services.map( ( element ) =>
									<option key={ element.id } value={ element.id }>{ element.title }</option>
								)
							}
						</Form.Select>
					</Form.Group>
					<Form.Group>
						<Form.Label>Стоимость</Form.Label>
						<FormControlNumber placeholder="Стоимость" value={ cost } onChange={ setCost } />
					</Form.Group>
					<Form.Group>
						<Form.Label>Отключено</Form.Label>
						<Form.Check type="checkbox" checked={ isDisabled } onChange={ onIsDisabledChange } />
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={ onClose }>
					Отмена
				</Button>
				<Button variant="primary" onClick={ onSave } disabled={ isSaveButtonDisabled }>
					Записать
					{ showLoading && <Spinner animation="border" variant="warning" /> }
				</Button>
			</Modal.Footer>
		</Modal>
	);
}
