import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Row, Tab, Table, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Activity } from "../Activity/Activity";
import * as api from "../api/api";
import { BalanceModal } from "../BalanceModal/BalanceModal";
import { Transactions } from "../Transactions/Transactions";
import { UserType } from "../types/UserType";
import { getFormattedDateTime } from "../util/getFormattedDateTime";



export function UserDetails()
{
	let { userId: stringUserId } = useParams();

	const [ userId, setUserId ] = useState<number | undefined>( undefined );
	const [ user, setUser ] = useState<UserType | undefined>( undefined );
	const [ userStatistics, setUserStatistics ] = useState<any | undefined>( undefined );
	const [ showChangeBalaceModal, setShowChangeBalaceModal ] = useState<boolean>( false );

	useEffect( () =>
	{
		if ( !stringUserId || isNaN( parseInt( stringUserId ) ) )
			return;

		setUserId( parseInt( stringUserId ) );
	}, [] );

	useEffect( () => { updateUserData(); }, [ userId ] );

	const updateUserData = async () =>
	{
		if ( !userId )
			return;

		const [ userDetails, userStatisticsData ] = await Promise.all(
			[
				api.getUser( +userId ),
				api.getUserStatistics( +userId )
			] );

		setUser( userDetails );
		setUserStatistics( userStatisticsData );
	};


	const toggleUserBlock = async () =>
	{
		if( !userId )
			return;

		await api.toggleUserBlock( userId );
		updateUserData();
	};

	const onBalanceEditButtonClick = () => setShowChangeBalaceModal( true );
	const onBalanceEditModalClose = () => setShowChangeBalaceModal( false );
	const onBalanceUpdate = () =>
	{
		updateUserData();
		onBalanceEditModalClose();
	};

	if ( !user )
		return null;

	return (
		<div>
			{
				showChangeBalaceModal &&
				<BalanceModal userId={ userId } onClose={ onBalanceEditModalClose } onSave={ onBalanceUpdate } />
			}
			<Breadcrumb>
				<Breadcrumb.Item><Link to="/">Главная</Link></Breadcrumb.Item>
				<Breadcrumb.Item><Link to="/users">Пользователи</Link></Breadcrumb.Item>
				<Breadcrumb.Item active>ID: { user.id } ({ user.email })</Breadcrumb.Item>
			</Breadcrumb>

			<Row>
				<Col>
					<Card style={ { width: '48rem' } }>
						<Card.Body>
							<Card.Title>
								User ID: { user.id }
								{ user.isBlocked && <strong className="red">&nbsp;ЗАБЛОКИРОВАН</strong> }
								{ user.isDeleted && <strong className="red">&nbsp;УДАЛЁН</strong> }
							</Card.Title>
							<Card.Subtitle className="mb-2 text-muted">{ user.email }</Card.Subtitle>
							<Card.Subtitle className="mb-2 text-muted">Баланс: ${ user.balance }</Card.Subtitle>
							<Card.Text>
								<Row md={ 12 }>
									<Col md={ 4 }>
										<Row>
											<Col>
												Сумма пополнений: <strong>${ rounded( userStatistics?.refillAmount || 0 ) }</strong>
											</Col>
										</Row>
										<Row>
											<Col>
												Сумма затрат: <strong>${ rounded( userStatistics?.expensesAmount || 0 ) }</strong>
											</Col>
										</Row>
										<Row>
											<Col>
												Номеров запрошено: <strong>{ userStatistics?.phoneNumberRentQueries }</strong>
											</Col>
										</Row>
										<Row>
											<Col>
												Номеров куплено: <strong>{ userStatistics?.purchasedPhoneNumbers || 0 }</strong>
											</Col>
										</Row>
									</Col>

									<Col md={ 8 }>
										<Row>
											<Col>
												Дата создания профиля: <strong>{ getFormattedDateTime( user.createdAt ) }</strong>
											</Col>
										</Row>
										<Row>
											<Col>
												Дата принятия GDPR: <strong>{ user?.dataCollectionAcceptedAt ? getFormattedDateTime( user.dataCollectionAcceptedAt ) : "Не принял" }</strong>
											</Col>
										</Row>
										<Row>
											<Col>
												Дата последнего действия: <strong>{ userStatistics?.lastAction?.date || "Нет" }</strong>
											</Col>
										</Row>
										<Row>
											<Col>
												Последний IP: <strong>{ userStatistics?.lastAction?.ip || "Нет" }</strong>
											</Col>
										</Row>
									</Col>
								</Row>
							</Card.Text>
						</Card.Body>
					</Card>
				</Col>

				<Col>
					<Row>
						<Col>
							<Button variant="danger" onClick={ toggleUserBlock }>
								{ user.isBlocked ? "Разблокировать" : "Заблокировать" }
							</Button>
						</Col>
					</Row>

					<Row className="marginTop">
						<Col>
							<Button variant="warning" onClick={ onBalanceEditButtonClick }>
								Изменить баланс
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>

			{
				user.comment &&
					<Row md={ 12 }>
						<Table striped bordered hover>
							<thead>
								<tr>
									<th>Комментарий</th>
								</tr>
							</thead>
							<tbody>
								{
									user.comment.split( "\n" ).map( ( comment, i ) =>
										<tr key={ i }>
											<td>{ comment }</td>
										</tr>
									)
								}
							</tbody>
						</Table>
					</Row>
			}

			<Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
				<Tab eventKey="indynumber" title="Активность">
					<Activity userId={ userId } />
				</Tab>
				<Tab eventKey="transactions" title="Транзакции">
					<Transactions userId={ userId } />
				</Tab>
			</Tabs>
		</div>
	);
}


function rounded( number: number )
{
    return +number.toFixed( 2 );
}
