import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Breadcrumb, Button, Col, Form, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as api from "../api/api";
import { UserType } from "../types/UserType";



const FIND_BY_ID = 1;
const FIND_BY_EMAIL_PART = 2;


export function Users()
{
	const [ users, setUsers ] = useState<UserType[]>( [] );
	const [ searchString, setSearchString ] = useState<string>( "" );
	let navigate = useNavigate();

	// useEffect( () => { updateUsers() }, [] );


	const updateUsers = async () =>
	{
		const usersList = await api.getUsersList();
		setUsers( usersList );
	}


	const resetSearch = () =>
	{
		setSearchString( "" );
		updateUsers();
	};


	const findUser = async ( mode: number ) =>
	{
		if( !searchString )
			return;

		const filter: any = {};

		if( mode === FIND_BY_ID )
			filter.id = +searchString;
		else
		if( mode === FIND_BY_EMAIL_PART )
			filter.emailPart = searchString;
		else
			throw new Error( "WTF?" );

		const usersList = await api.findUsers( filter );
		setUsers( usersList );
	};

	const onSearchStringUpdate = ( event: any ) => setSearchString( event.target.value );

	const onSearchStringKeyPress = ( event: any ) =>
	{
		if (event.key === "Enter")
		{
			event.preventDefault();
			setSearchString( "" );
			findUser( FIND_BY_ID );
		}
	}

	const onUserSelect = ( userId: number ) =>
	{
		const url = "/users/" + userId;
		navigate( url );
	};

	return (
		<div>
			<Breadcrumb>
				<Breadcrumb.Item><Link to="/">Главная</Link></Breadcrumb.Item>
				<Breadcrumb.Item active>Пользователи</Breadcrumb.Item>
			</Breadcrumb>
			<Form>
				<Row md={ 12 }>
					<Col md={ 4 }>
						<Form.Group>
							<Form.Control
								type="text"
								placeholder="Строка поиска"
								value={ searchString }
								onChange={ onSearchStringUpdate }
								onKeyDown={ onSearchStringKeyPress } />
						</Form.Group>
					</Col>

					<Col md={ 8 }>
						<Button variant="primary" disabled={ !searchString } onClick={ () => findUser( FIND_BY_ID ) }>
							Найти по ID
						</Button>
						&nbsp;
						<Button variant="primary" disabled={ !searchString } onClick={ () => findUser( FIND_BY_EMAIL_PART ) }>
							Найти по-email
						</Button>
						&nbsp;
						<Button variant="danger" onClick={ resetSearch }>
							Сбросить
						</Button>
					</Col>
				</Row>
			</Form>

			<Row>
				<Table striped bordered hover className="marginTop">
					<thead>
						<tr>
							<th>ID</th>
							<th>e-mail</th>
							<th>Баланс</th>
							<th>Дата создания</th>
						</tr>
					</thead>
					<tbody>
						{
							users.map( ( user ) =>
									<tr key={ user.id } onClick={ () => onUserSelect( user.id ) }>
										<td>{ user.id }</td>
										<td>{ user.email }</td>
										<td>${ user.balance }</td>
										<td>{ user.createdAt }</td>
									</tr>
							)
						}
					</tbody>
				</Table>
			</Row>

		</div>
	);
}
