import { GoogleOAuthProvider } from "@react-oauth/google";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, } from "react-router-dom";
import App from "./App";
import * as api from "src/api/api";


const rootElement = document.getElementById( "root");
const root = ReactDOM.createRoot( rootElement! );

const router = createBrowserRouter([
	{
		path: "*",
		element: <App />,
	},
]);


if( api.isDebug() )
	root.render(
			<RouterProvider router={ router } />
	);
else
	root.render(
			<GoogleOAuthProvider clientId='24446013817-vmkh0jbrbbqj1upm5g0cnpvgqfutk8lo.apps.googleusercontent.com'>
				<RouterProvider router={ router } />
			</GoogleOAuthProvider>
	);
