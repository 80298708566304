import "bootstrap/dist/css/bootstrap.min.css";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Form, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as api from "../../api/api";
import { ServiceEntryType } from "../../types/ServiceEntryType";
import { ServiceEditModal } from "./ServiceEditModal/ServiceEditModal";



export function Services()
{
	const [ services, setServices ] = useState<ServiceEntryType[]>( [] );
	const [ allServices, setAllServices ] = useState<ServiceEntryType[]>( [] );
	const [ searchString, setSearchString ] = useState<string>( "" );
	const [ editService, setEditService ] = useState<ServiceEntryType | null | undefined>( undefined );

	const doSearch = debounce( () => findItem(), 300 );
	useEffect( () => { updateServices() }, [] );
	useEffect( () => { doSearch() }, [ searchString ] );


	// ***************************************************************************************************
	// ***************************************************************************************************
	const updateServices = async () =>
	{
		const servicesList = await api.getServicesList();
		setAllServices( servicesList );
		setServices( servicesList );
	}


	// ***************************************************************************************************
	// ***************************************************************************************************
	const findItem = async () =>
	{
		if( !searchString )
		{
			if( services.length !== allServices.length )
				setServices( allServices );

			return;
		}

		const searchStringLowerCase = searchString.toLowerCase();
		const filteredServices = allServices.filter( ( element ) => element.title.toLowerCase().includes( searchStringLowerCase ) );

		setServices( filteredServices );
	};


	// ***************************************************************************************************
	// ***************************************************************************************************
	const onSearchStringUpdate = ( event: any ) => setSearchString( event.target.value );
	const onSearchStringKeyPress = ( event: any ) =>
		{
			if (event.key === "Enter")
				event.preventDefault();
		}


	// ***************************************************************************************************
	// ***************************************************************************************************
	const addService = () => setEditService( null );
	const onServiceEditModalClose = () => setEditService( undefined );
	const onServiceEditModalSave = () =>
	{
		updateServices();
		onServiceEditModalClose();
	};
	const deleteProduct = async ( service: ServiceEntryType, event: any ) =>
	{
		event.stopPropagation();

		/* eslint-disable no-restricted-globals */
		const result = confirm( "Вы уверены, что хотите удалить эту запись?" );

		if( !result )
			return;

		await api.deleteService( service.id );
		updateServices();
	}


	// ***************************************************************************************************
	// ***************************************************************************************************
	return (
		<div>
			{
				editService !== undefined &&
					<ServiceEditModal service={ editService } onClose={ onServiceEditModalClose } onSave={ onServiceEditModalSave } />
			}
			<Breadcrumb>
				<Breadcrumb.Item><Link to="/">Главная</Link></Breadcrumb.Item>
				<Breadcrumb.Item active>Сервисы</Breadcrumb.Item>
			</Breadcrumb>
			<Form>
				<Row>
					<Form.Group as={ Col }>
						<Form.Label>Поиск</Form.Label>
						<Form.Control
							type="text"
							style={{width:"50%"}}
							value={ searchString }
							onChange={ onSearchStringUpdate }
							onKeyPress={ onSearchStringKeyPress } />
					</Form.Group>
				</Row>
			</Form>

			<Table striped bordered hover className="marginTop">
				<thead>
					<tr>
						<th>ID</th>
						<th>Наименование</th>
						<th>SMS-Activate</th>
						<th>5sim</th>
						<th>onlineSIM</th>
						<th>Иконка</th>
						<th><Button variant="primary" onClick={ addService }>Добавить</Button></th>
					</tr>
				</thead>
				<tbody>
					{
						services.map( ( service ) =>
							<tr key={ service.id } onClick={ () => setEditService( service ) }>
								<td>{ service.id }</td>
								<td>{ service.title }</td>
								<td>{ service.smsActivateCode }</td>
								<td>{ service.fiveSimCode }</td>
								<td>{ service.onlineSimCode }</td>
								<td><Icon file={ service.icon } /></td>
								<td><Button variant="danger" onClick={ ( event ) => deleteProduct( service, event ) }>Удалить</Button></td>
							</tr>
						)
					}
				</tbody>
			</Table>

		</div>
	);
}


// ***************************************************************************************************
// ***************************************************************************************************
function Icon( props: any )
{
	const file = props.file;

	if( !file )
		return null;

	return <img src={ "/icon/services/" + file } height="20" alt="Иконка" />
}
