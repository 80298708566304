import "bootstrap/dist/css/bootstrap.min.css";
import getUnixTime from "date-fns/getUnixTime";
import memoize from "lodash/memoize";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import * as api from "../api/api";
import { DateRange } from "../common/DateRange";
import { CountryEntryType } from "../types/CountryEntryType";
import { Event } from "../types/Event";
import { OrderType } from "../types/IndynumberOrderType";
import { LogType } from "../types/LogType";
import { OrderStatuses } from "../types/OrderStatuses";
import { ServiceEntryType } from "../types/ServiceEntryType";
import { getFormattedDateTime } from "../util/getFormattedDateTime";


interface ActivityProps
{
	userId?: number
}


// ***************************************************************************************************
// ***************************************************************************************************
export function Activity( props: ActivityProps )
{
	const [ userId, setUserId ] = useState<number | null>( props?.userId || null );
	const [ orders, setOrders ] = useState<OrderType[]>( [] );
	const [ countries, setCountries ] = useState<CountryEntryType[]>( [] );
	const [ services, setServices ] = useState<ServiceEntryType[]>( [] );

	const [ phoneFilter, setPhoneFilter ] = useState<string>( "" );
	const [ statusFilter, setStatusFilter ] = useState<number | null>( null );
	const [ dateFromFilter, setDateFromFilter ] = useState<Date | null>( null );
	const [ dateToFilter, setDateToFilter ] = useState<Date | null>( null );
	const [ isFilterReset, setIsFilterReset ] = useState<boolean>( false );

	const getCountryTitleById = memoize( ( countryId ) => countries.find( ( element ) => element.id === countryId )?.title || "" );
	const getServiceTitleById = memoize( ( serviceId ) => services.find( ( element ) => element.id === serviceId )?.title || "" );

	useEffect( () =>
	{
		async function loadInitialData()
		{
			const [ servicesList, countriesList, ordersList ] =
				await Promise.all(
					[
						api.getServicesList(),
						api.getCountriesList(),
						updateOrders()
					] );

			setCountries( countriesList );
			setServices( servicesList );
		}

		loadInitialData();
	}, [] );

	// useEffect( () =>
	// {
	// 	if( !isFilterReset || phoneFilter || statusFilter || dateFromFilter || dateToFilter )
	// 		return;

	// 	setIsFilterReset( false );
	// 	updateOrders();
	// },
	// [ phoneFilter, statusFilter, dateFromFilter, dateToFilter ])


	const updateOrders = async ( loadInfilteredDate: boolean = false ) =>
	{
		const filters = loadInfilteredDate ?
		{
			userId,
			phone: "",
			status: null,
			dateFromTimestamp: null,
			dateToTimestamp: null
		} :
		{
			userId,
			phone: phoneFilter,
			status: statusFilter,
			dateFromTimestamp: dateFromFilter && getUnixTime( dateFromFilter ),
			dateToTimestamp: dateToFilter && getUnixTime( dateToFilter )
		};

		const ordersList = await api.getOrders( filters );
		setOrders( ordersList );
	}


	// ***************************************************************************************************
	// ***************************************************************************************************
	const onSearchStringUpdate = ( event: any ) => setPhoneFilter( event.target.value );
	const onSearchStringKeyPress = ( event: any ) =>
		{
			if (event.key === "Enter")
				event.preventDefault();
		}
	const onStatusFilterChange = ( event: any ) => setStatusFilter( +event.target.value || null );
	const resetFilter = () =>
		{
			setPhoneFilter( "" );
			setStatusFilter( null );
			setDateFromFilter( null );
			setDateToFilter( null );

			updateOrders( true );
		};


	return (
		<>
			<Row>
				<Col md={ 2 }>
					<Form.Group>
						<Form.Label>Номер телефона</Form.Label>
						<Form.Control
							type="text"
							value={ phoneFilter }
							onChange={ onSearchStringUpdate }
							onKeyPress={ onSearchStringKeyPress } />
					</Form.Group>
				</Col>

				<Col md={ 3 }>
					<Form.Group>
						<Form.Label>Статус</Form.Label>
						<Form.Select value={ statusFilter || "" } onChange={ onStatusFilterChange }>
							<option value={ "" }></option>
							<option value={ OrderStatuses.PHONE_NUMBER_RELEASED }>Номер освобожден</option>
							<option value={ OrderStatuses.RENT_CANCELLED }>Операция отменена</option>
						</Form.Select>
					</Form.Group>
				</Col>

				<DateRange
					dateFrom={ dateFromFilter }
					dateTo={ dateToFilter }
					onDateFromChange={ setDateFromFilter }
					onDateToChange={ setDateToFilter } />

				<Col md={ 2 }>
					<Button variant="danger" onClick={ resetFilter } style={{marginTop: 32}}>
						Сбросить фильтр
					</Button>
				</Col>
				<Col md={ 2 }>
					<Button variant="primary" onClick={ () => updateOrders() } style={{marginTop: 32}}>
						Найти
					</Button>
				</Col>
			</Row>

			<Row>
				<Table striped bordered hover className="marginTop">
					<thead>
						<tr>
							<th>id</th>
							<th>Дата</th>
							<th>ID пользователя</th>
							<th>Провайдер</th>
							<th>ID провайдера</th>
							<th>Телефон</th>
							<th>Страна</th>
							<th>Сервис</th>
							<th>Последний статус</th>
							<th>Цена продажи</th>
							<th>Цена закупки</th>
							<th>Прибыль</th>
						</tr>
					</thead>
					<tbody>
						{
							orders.map( ( order ) =>
								<Order key={ order.id } order={ order } getServiceTitleById={ getServiceTitleById } getCountryTitleById={ getCountryTitleById } /> )
						}
					</tbody>
				</Table>
			</Row>
		</>
	);
}


// ***************************************************************************************************
// ***************************************************************************************************
interface OrderProps
{
	order: OrderType,
	getServiceTitleById: ( id: number ) => string,
	getCountryTitleById: ( id: number ) => string
}


// ***************************************************************************************************
// ***************************************************************************************************
function Order( props: OrderProps )
{
	const { order, getServiceTitleById, getCountryTitleById } = props;

	const [ isExpanded, setIsExpanded ] = useState<boolean>( false );
	const [ logs, setLogs ] = useState<LogType[]>( [] );

	useEffect( () => { onExpand() }, [ isExpanded ] );

	const onExpand = async () =>
	{
		if( !isExpanded || logs.length )
			return;

		const logsList = await api.getLogsByOrderId( order.id );
		setLogs( logsList );
	};
	const onRowClick = () => setIsExpanded( !isExpanded );


	return (
		<>
			<tr key={ order.id } onClick={ onRowClick }>
				<td>{ order.id }</td>
				<td>{ getFormattedDateTime( order.date ) }</td>
				<td>{ order.userId }</td>
				<td>{ getProviderTitle( order.providerId ) }</td>
				<td>{ order.providerOperationId }</td>
				<td>{ order.phoneNumber }</td>
				<td>{ getCountryTitleById( order.countryId ) }</td>
				<td>{ getServiceTitleById( order.serviceId ) }</td>
				<td>{ getOrderStringStatus( order.status ) }</td>
				<td>{ order.sellingPrice || "-" }</td>
				<td>{ order.purchasePrice || "-" }</td>
				<td>{ order.profit || "-" }</td>
			</tr>
			{
				isExpanded &&
					<>
						<tr>
							<td className="blue" colSpan={ 8 }></td>
						</tr>
						<tr>
							<td colSpan={ 4 }></td>
							<td><strong>Дата</strong></td>
							<td><strong>Событие</strong></td>
							<td><strong>Текст SMS</strong></td>
							<td><strong>IP</strong></td>
						</tr>
						{
							logs.map( ( log ) =>
								<tr key={ log.id }>
									<td colSpan={ 4 }></td>
									<td>{ getFormattedDateTime( log.date ) }</td>
									<td>{ getLogStringEvent( log.event ) }</td>
									<td>{ log.smsText }</td>
									<td>{ log.ip }</td>
								</tr> )
						}
						<tr>
							<td className="blue" colSpan={ 8 }></td>
						</tr>
					</>
			}
		</>
	)
}


// ***************************************************************************************************
// ***************************************************************************************************
function getProviderTitle( providerId: number ): string
{
	if( providerId === 1 )
		return "SMS Activate";

	if( providerId === 2 )
		return "5SIM";

	return "Неизвестный провайдер";
}


// ***************************************************************************************************
// ***************************************************************************************************
function getOrderStringStatus( status: OrderStatuses )
{
	if( status === OrderStatuses.PHONE_NUMBER_RENTED )
		return "Номер выдан";

	if( status === OrderStatuses.WAITING_FOR_SMS )
		return "Ожидание SMS";

	if( status === OrderStatuses.SMS_RECEIVED )
		return "SMS получена";

	if( status === OrderStatuses.PHONE_NUMBER_RELEASED )
		return "Номер освобожден";

	if( status === OrderStatuses.RENT_CANCELLED )
		return "Операция отменена";

	return "Неизвестный статус";
}


// ***************************************************************************************************
// ***************************************************************************************************
function getLogStringEvent( event: Event )
{
	if( event === Event.PHONE_NUMBER_RENTED )
		return "Номер выдан";

	if( event === Event.WAITING_FOR_SMS )
		return "Ожидание SMS";

	if( event === Event.OBTAIN_SMS_QUERY )
		return "Запрос на получение SMS";

	if( event === Event.SMS_RECEIVED )
		return "SMS получена";

	if( event === Event.PHONE_NUMBER_RELEASED )
		return "Номер освобожден";

	if( event === Event.RENT_CANCELLED )
		return "Операция отменена";

	return "Неизвестное событие";
}
