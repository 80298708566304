import { useGoogleLogin } from "@react-oauth/google";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Button } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import * as api from "../api/api";



// ***************************************************************************************************
// ***************************************************************************************************
export function Login()
{
	let navigate = useNavigate();

    const login = useGoogleLogin(
		{
			flow: "auth-code",
			onSuccess: async (tokenResponse: any) =>
			{
				console.log( `authCode: ${tokenResponse.code}`, tokenResponse );

				const jwt = await api.login( tokenResponse.code );

				if( jwt )
					navigate( "/" );
				else
					alert( "Can't login" );
			}
    	} );


	if( api.isJwtSet() )
		return <Navigate to="/" />;

	return (
		<div>
			<Button variant="primary" onClick={ login }>
				Sign in with Google
			</Button>
		</div>
	);
}
