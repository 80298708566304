import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import * as api from "../api/api";



// ***************************************************************************************************
// ***************************************************************************************************
export function BalanceModal( props: any )
{
	const { userId, onClose } = props;
	const [ amount, setAmount ] = useState<number>( 0 );


	// ***************************************************************************************************
	// ***************************************************************************************************
	const onSave = async () =>
	{
		if( !amount )
			return;

		try
		{
			await api.updateBalanceByAdmin( userId, amount );
			props.onSave();
		}
		catch( error )
		{
			console.log( "Ошибка изменения баланса:", error );
			alert( "Ошибка изменения баланса! Попробуйте повторить попытку позже." );
		}
	};


	// ***************************************************************************************************
	// ***************************************************************************************************
	const onAmountChange = ( event: any ) => setAmount( +event.target.value );

	return (
		<Modal show={ true } onHide={ onClose }>
			<Modal.Header closeButton>
				<Modal.Title>Изменение баланса</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>Сумма пополнения/списания</Form.Label>
						<Form.Control
							type="number"
							placeholder="Сумма пополнения"
							value={ amount }
							onChange={ onAmountChange } />
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={ onClose }>
					Отмена
				</Button>
				<Button variant="primary" onClick={ onSave }>
					Записать
				</Button>
			</Modal.Footer>
		</Modal>
	);

}
