import React, { useEffect, useState } from "react";
import { Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { getServicesBalance, getUsersCount } from "../api/api";


// ***************************************************************************************************
// ***************************************************************************************************
export function Main()
{
	const [ usersCount, setUsersCount ] = useState<number>( 0 );
	const [ smsActivateBalance, setSmsActivateBalance ] = useState<number>( 0 );
	const [ fiveSimBalance, setFiveSimBalance ] = useState<number>( 0 );
	const [ onlineSimBalance, setOnlineSimBalance ] = useState<number>( 0 );

	useEffect( () => { getData() }, [] );

	const getData = async () =>
	{
		const [ countResult, balanceResult ] = await Promise.all(
			[
				getUsersCount(),
				getServicesBalance()
			] )

		setUsersCount( countResult.count );

		setSmsActivateBalance( balanceResult.smsActivateBalance );
		setFiveSimBalance( balanceResult.fiveSimBalance );
		setOnlineSimBalance( balanceResult.onlineSimBalance );
	};



	return (
		<div>
			<Breadcrumb>
				<Breadcrumb.Item active>Главная</Breadcrumb.Item>
			</Breadcrumb>

			<Row>
				<h2>Добро пожаловать в административную панель!</h2>
			</Row>

			<Card style={ { width: '48rem' } }>
				<Card.Body>
					<Card.Title>Статистика</Card.Title>
					<Card.Text>
						<Row><Col>Общее число пользователей: <strong>{ usersCount }</strong></Col></Row>
						<Row><Col>Баланс SMS Activate: <strong>{ smsActivateBalance } руб.</strong></Col></Row>
						<Row><Col>Баланс 5sim: <strong>{ fiveSimBalance } руб.</strong></Col></Row>
						<Row><Col>Баланс onlineSIM: <strong>{ onlineSimBalance } руб.</strong></Col></Row>
					</Card.Text>
				</Card.Body>
			</Card>
		</div>
	);
}
