import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Col, Container, ListGroup, Navbar, Row } from "react-bootstrap";
import { Link, Navigate, Outlet, Route, Routes } from "react-router-dom";
import { Activity } from "./Activity/Activity";
import { Config } from "./Config/Config";
import { Countries } from "./Helpers/Countries/Countries";
import { Products } from "./Helpers/Products/Products";
import { Providers } from "./Helpers/Providers/Providers";
import { Services } from "./Helpers/Services/Services";
import { Login } from "./Login/Login";
import { Main } from "./Main/Main";
import { Pricelist } from "./Pricelist/Pricelist";
import { Refunds } from "./Refunds/Refunds";
import { Transactions } from "./Transactions/Transactions";
import { UserDetails } from "./UserDetails/UserDetails";
import { Users } from "./Users/Users";
import * as api from "./api/api";
import "./css/style.css";



// ***************************************************************************************************
// ***************************************************************************************************
export default function App()
{
	const ProtectedRoute = () =>
	{
		if( !api.isDebug() && !api.isJwtSet() )
		{
			return <Navigate to="/login" replace />;
		}

		return <Outlet />;
	};

	return (
		<Container fluid>
			<Navbar bg="light" expand="lg">
				<Container>
					<Navbar.Brand href="#home">Панель администрирования <strong>Indynumber</strong></Navbar.Brand>
				</Container>
			</Navbar>
			<Row>
				<Col xs={ 2 } id="sidebar-wrapper">
					<NavigationSidebar />
				</Col>

				<Col xs={ 10 } id="page-content-wrapper">
					<Routes>
						<Route path="/login" element={ <Login /> } />
						<Route element={ <ProtectedRoute /> }>
							<Route index path="/" element={ <Main /> } />
							<Route path="/users" element={ <Users /> } />
							<Route path="/users/:userId" element={ <UserDetails /> } />
							<Route path="/users" element={ <Users /> } />
							<Route path="/activity" element={ <Activity /> } />
							<Route path="/transactions" element={ <Transactions /> } />
							<Route path="/refunds" element={ <Refunds /> } />
							<Route path="/pricelist" element={ <Pricelist /> } />
							<Route path="/helpers/providers" element={ <Providers /> } />
							<Route path="/helpers/countries" element={ <Countries /> } />
							<Route path="/helpers/services" element={ <Services /> } />
							<Route path="/helpers/products" element={ <Products /> } />
							<Route path="/config" element={ <Config /> } />
						</Route>
					</Routes>
				</Col>
			</Row>
		</Container>
	);
}


// ***************************************************************************************************
// ***************************************************************************************************
export function NavigationSidebar()
{
	return (
		<ListGroup className="main_navigation">
			<ListGroup.Item action>
				<Link to="/">Главная</Link>
			</ListGroup.Item>
			<ListGroup.Item action>
				<Link to="/users">Пользователи</Link>
			</ListGroup.Item>
			<ListGroup.Item action>
				<Link to="/activity">Пользовательская активность</Link>
			</ListGroup.Item>
			<ListGroup.Item action>
				<Link to="/transactions">Транзакции</Link>
			</ListGroup.Item>
			<ListGroup.Item action>
				<Link to="/refunds">Google Refunds</Link>
			</ListGroup.Item>
			<ListGroup.Item action>
				<Link to="/pricelist">Прайс-лист</Link>
			</ListGroup.Item>
			<ListGroup.Item action>
				<Link to="/config">Настройки</Link>
			</ListGroup.Item>
			<div className="span">
				<ListGroup.Item disabled>
					<strong>Справочники</strong>
				</ListGroup.Item>
				<ListGroup.Item action>
					<Link to="/helpers/providers">Платформы</Link>
				</ListGroup.Item>
				<ListGroup.Item action>
					<Link to="/helpers/countries">Страны</Link>
				</ListGroup.Item>
				<ListGroup.Item action>
					<Link to="/helpers/services">Сервисы</Link>
				</ListGroup.Item>
				<ListGroup.Item action>
					<Link to="/helpers/products">Продукты</Link>
				</ListGroup.Item>
			</div>
		</ListGroup>
	);
}
