import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as api from "../../api/api";
import { ProductType } from "../../types/ProductType";
import { ProductEditModal } from "./ProductEditModal/ProductEditModal";



export function Products()
{
	const [ products, setProducts ] = useState<ProductType[]>( [] );
	const [ productToEdit, setProductToEdit ] = useState<ProductType | null | undefined>( undefined );

	useEffect( () => { updateProducts() }, [] );


	// ***************************************************************************************************
	// ***************************************************************************************************
	const updateProducts = async () =>
	{
		const productsList = await api.getProductsList();
		setProducts( productsList );
	}


	// ***************************************************************************************************
	// ***************************************************************************************************
	const addProduct = () => setProductToEdit( null );
	const onProductEditModalClose = () => setProductToEdit( undefined );
	const onProductEditModalSave = () =>
	{
		updateProducts();
		onProductEditModalClose();
	};

	const toggleDisabled = async ( product: ProductType, event: any ) =>
	{
		event.stopPropagation();

		/* eslint-disable no-restricted-globals */
		const result = confirm( `Вы уверены, что хотите ${ product.isDisabled ? "включить" : "отключить" } этот продукт'?` );

		if( !result )
			return;

		await api.toggleDisabled( product.id );
		updateProducts();
	}


	// ***************************************************************************************************
	// ***************************************************************************************************
	return (
		<div>
			{
				productToEdit !== undefined &&
					<ProductEditModal product={ productToEdit } onClose={ onProductEditModalClose } onSave={ onProductEditModalSave } />
			}

			<Breadcrumb>
				<Breadcrumb.Item><Link to="/">Главная</Link></Breadcrumb.Item>
				<Breadcrumb.Item active>Продукты</Breadcrumb.Item>
			</Breadcrumb>

			<Table striped bordered hover className="marginTop">
				<thead>
					<tr>
						<th>ID</th>
						<th>Наименование в магазине</th>
						<th>Наименование</th>
						<th>Сумма к начислению</th>
						<th>Цена</th>
						<th><Button variant="primary" onClick={ addProduct }>Добавить</Button></th>
					</tr>
				</thead>
				<tbody>
					{
						products.map( ( product ) =>
							<tr key={ product.id } onClick={ () => setProductToEdit( product ) }>
								<td>{ product.id }</td>
								<td>{ product.marketId }</td>
								<td>{ product.title }</td>
								<td>{ product.amountToEnroll }</td>
								<td>{ product.price }</td>
								<td><Button variant={ product.isDisabled ? "primary" : "danger" } onClick={ ( event ) => toggleDisabled( product, event ) }>{ product.isDisabled ? "Включить" : "Выключить" }</Button></td>
							</tr>
						)
					}
				</tbody>
			</Table>

		</div>
	);
}

