import React, { useState } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { ConfigType, ConfigValueType } from "../../api/types/ConfigType";


interface EditConfigItemModalPropsType
{
	config: ConfigType,
	onClose: () => void,
	onSave: ( config: ConfigType ) => void
}




// ***************************************************************************************************
// ***************************************************************************************************
export function EditConfigItemModal( props: EditConfigItemModalPropsType )
{
	const { config, onClose } = props;
	const [ value, setValue ] = useState<string>( config.value );
	const [ errorMessage, setErrorMessage ] = useState<string | null>( null );


	// ***************************************************************************************************
	// ***************************************************************************************************
	const onSave = async () =>
	{
		if( errorMessage )
			return;

		const newConfig = { ...config, value };
		props.onSave( newConfig );
	};


	// ***************************************************************************************************
	// ***************************************************************************************************
	const onValueChange = ( event: any ) =>
	{
		if( config.type === ConfigValueType.Bool )
		{
			setValue( event.target.checked.toString() );
			return;
		}

		setValue( event.target.value );

		if( config.type === ConfigValueType.Int )
		{
			const numberValue = parseInt( value );

			if( isNaN( numberValue ) || !Number.isInteger( numberValue ) )
			{
				setErrorMessage( "Необходимо ввести целое число" );
				return;
			}
		}
		else
		if( config.type === ConfigValueType.Float )
		{
			if( isNaN( parseFloat( event.target.value ) ) )
			{
				setErrorMessage( "Необходимо ввести целое или дробное число" );
				return;
			}
		}

		if( errorMessage )
			setErrorMessage( null );
	}


	// ***************************************************************************************************
	// ***************************************************************************************************
	const haveError = !!errorMessage;

	return (
		<Modal show={ true } onHide={ onClose }>
			<Modal.Header closeButton>
				<Modal.Title>Редактирование конфигурации</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>{ config.title }</Form.Label>
						{
							config.type === ConfigValueType.String &&
								<Form.Control
									type="boolean"
									value={ value }
									isInvalid={ haveError }
									onChange={ onValueChange } />
						}
						{
							( config.type === ConfigValueType.Int || config.type === ConfigValueType.Float ) &&
								<Form.Control
									type="number"
									value={ value }
									isInvalid={ haveError }
									onChange={ onValueChange } />
						}
						{
							config.type === ConfigValueType.Bool &&
								<Form.Check type="checkbox" checked={ value === "true" } onChange={ onValueChange } />
						}
						{
							haveError &&
								<Alert variant="danger">{ errorMessage }</Alert>
						}
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={ onClose }>
					Отмена
				</Button>
				<Button variant="primary" onClick={ onSave } disabled={ haveError }>
					Записать
				</Button>
			</Modal.Footer>
		</Modal>
	);

}
