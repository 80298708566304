import "bootstrap/dist/css/bootstrap.min.css";
import memoize from "lodash/memoize";
import React, { useCallback, useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Form, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as api from "../api/api";
import { CountryEntryType } from "../types/CountryEntryType";
import { PriceEntryType } from "../types/PriceEntryType";
import { ServiceEntryType } from "../types/ServiceEntryType";
import { ImportPriceModal } from "./ImportPriceModal/ImportPriceModal";
import { PricelistEditModal } from "./PricelistEditModal/PricelistEditModal";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";



export function Pricelist()
{
	const [ prices, setPrices ] = useState<PriceEntryType[]>( [] );
	const [ countries, setCountries ] = useState<CountryEntryType[]>( [] );
	const [ services, setServices ] = useState<ServiceEntryType[]>( [] );
	const [ filterCountry, setFilterCountry ] = useState<CountryEntryType[]>( [] );
	const [ filterService, setFilterService ] = useState<ServiceEntryType[]>( [] );
	const [ showImportPriceModal, setShowImportPriceModal] = useState<boolean>( false );
	const [ priceToEdit, setPriceToEdit ] = useState<PriceEntryType | null | undefined>( undefined );


	// ***************************************************************************************************
	// ***************************************************************************************************
	const updatePricelist = useCallback( async () =>
	{
		console.log( "updatePricelist")
		const pricelist = await api.getPricelist( filterCountry[0]?.id, filterService[0]?.id );

		setPrices( pricelist );
	}, [ filterCountry, filterService ] );


	useEffect( () => { updateHelpers() }, [] );
	useEffect( () => {
		console.log( "we will update price" )
		updatePricelist() }, [ filterCountry, filterService, updatePricelist ] );

	const getCountryTitleById = memoize( ( countryId ) => countries.find( ( element ) => element.id === countryId )?.title || "" );
	const getServiceTitleById = memoize( ( serviceId ) => services.find( ( element ) => element.id === serviceId )?.title || "" );


	// ***************************************************************************************************
	// ***************************************************************************************************
	const updateHelpers = async () =>
	{
		const [ countriesList, servicesList ] = await Promise.all(
			[
				api.getCountriesList(),
				api.getServicesList()
			] );

		setCountries( countriesList );
		setServices( servicesList );
	}


	// ***************************************************************************************************
	// ***************************************************************************************************
	// const onFilterChange = useCallback( () => updatePrices(), [ filterCountry, filterService ] );



	// ***************************************************************************************************
	// ***************************************************************************************************
	// const onCountryFilterChange = ( event: any ) => setFilterCountry( +event.target.value || undefined );
	const onCountryFilterChange = ( selected ) => setFilterCountry( selected );
	const onServiceFilterChange = ( selected ) => setFilterService( selected );
	const onFilterReset = () =>
	{
		setFilterCountry( [] );
		setFilterService( [] );
	};


	// ***************************************************************************************************
	// ***************************************************************************************************
	// const isPricelistEntryExists = ( countryId: number, serviceId: number ) =>
	// 	allPrices.some( ( element ) => element.countryId === countryId && element.serviceId === serviceId  );
	const onImportPriceButtonClick = () => setShowImportPriceModal( true );
	const onImportPriceModalClose = () => updatePricelist().then( () => setShowImportPriceModal( false ) );


	// ***************************************************************************************************
	// ***************************************************************************************************
	const addPriceEntry = () => setPriceToEdit( null );
	const onPriceEditModalClose = () => setPriceToEdit( undefined );
	const onPriceEditModalSave = () => updatePricelist().then( onPriceEditModalClose );
	const deletePricelistEntry = async ( price: PriceEntryType, event: any ) =>
	{
		event.stopPropagation();

		/* eslint-disable no-restricted-globals */
		const result = confirm( "Вы уверены, что хотите удалить эту запись?" );

		if( !result )
			return;

		await api.deletePricelistEntry( price.id );
		updatePricelist();
	}



	// ***************************************************************************************************
	// ***************************************************************************************************
	return (
		<div>
			{
				priceToEdit !== undefined &&
					<PricelistEditModal
						priceEntry={ priceToEdit }
						countries={ countries }
						services={ services }
						onClose={ onPriceEditModalClose }
						onSave={ onPriceEditModalSave } />
			}
			{
				showImportPriceModal &&
					<ImportPriceModal onClose={ onImportPriceModalClose } />
			}

			<Breadcrumb>
				<Breadcrumb.Item><Link to="/">Главная</Link></Breadcrumb.Item>
				<Breadcrumb.Item active>Прайс-лист</Breadcrumb.Item>
			</Breadcrumb>

			<Row md={12}>
				<Row className="marginTop">
					<Col>
						<Button variant="primary" onClick={ () => window.open( "/admin_api/get_sms_activate_pricelist", "_blank" ) }>
							Сгененерировать прайс-лист SMS-activate
						</Button>
					</Col>
				</Row>
				<Row className="marginTop">
					<Col>
						<Button variant="primary" onClick={ () => window.open( "/admin_api/get_five_sim_pricelist", "_blank" ) }>
							Сгененерировать прайс-лист 5SIM
						</Button>
					</Col>
				</Row>
			</Row>

			<Row md={12}>
				<Col md={3}>
					<Form.Group>
						<Typeahead
							id="basic-typeahead-single"
							labelKey="title"
							onChange={ onCountryFilterChange }
							options={ countries }
							placeholder="Choose a country..."
							selected={ filterCountry }
						/>
					</Form.Group>
				</Col>

				<Col>
					<Form.Group>
						<Typeahead
							id="basic-typeahead-single"
							labelKey="title"
							onChange={ onServiceFilterChange }
							options={ services }
							placeholder="Choose a service..."
							selected={ filterService }
						/>
					</Form.Group>
				</Col>

				<Col>
					<Button variant="primary" onClick={ onFilterReset }>Сбросить фильтр</Button>
				</Col>

				<Col md={3}></Col>

				<Col md={2}>
					<Button variant="success" onClick={ onImportPriceButtonClick }>Импортировать прайс-лист</Button>
				</Col>
			</Row>

			<Table striped bordered hover className="marginTop">
				<thead>
					<tr>
						<th>ID</th>
						<th>Страна</th>
						<th>Сервис</th>
						<th>Стоимость</th>
						<th>Выключено</th>
						<th><Button variant="primary" onClick={ addPriceEntry }>Добавить</Button></th>
					</tr>
				</thead>
				<tbody>
					{
						prices.map( ( price ) =>
							<tr key={ price.id } onClick={ () => setPriceToEdit( price ) }>
								<td>{ price.id }</td>
								<td>{ getCountryTitleById( price.countryId ) }</td>
								<td>{ getServiceTitleById( price.serviceId ) }</td>
								<td>{ price.cost }</td>
								<td><Form.Check checked={ price.isDisabled } /></td>
								<td><Button variant="danger" onClick={ ( event ) => deletePricelistEntry( price, event ) }>Удалить</Button></td>
							</tr>
						)
					}
				</tbody>
			</Table>
		</div>
	);
}
