
export interface ConfigType
{
	id: string;
	title: string;
	value: string;
	type: ConfigValueType;
}


export enum ConfigValueType
{
	String = 1,
	Int = 2,
	Float = 3,
	Bool = 4
}


// Т.к. есть потребность указывать стоимость сервиса для каждой определенной
//  страны и типа сервиса, то идентификатор строчки конфига будет такой:
//  INDYNUMBER_SERVICE_COST_[COUNTRY_ID]_[SERVICE_ID]
export const INDYNUMBER_SERVICE_COST_PREFIX = "INDYNUMBER_SERVICE_COST_";
