import React, { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import * as api from "../../../api/api";
import { ProductType } from "../../../types/ProductType";
import { FormControlNumber } from "../../../util/FormControlNumber";
import { FormControlString } from "../../../util/FormControlString";


interface ProductEditModalProps
{
	product: ProductType | null,
	onClose: () => void,
	onSave: () => void
}


// ***************************************************************************************************
// ***************************************************************************************************
export function ProductEditModal( props: ProductEditModalProps )
{
	const { product, onClose } = props;
	const [ marketId, setMarketId ] = useState<string>( product?.marketId || "" );
	const [ title, setTitle ] = useState<string>( product?.title || "" );
	const [ amountToEnroll, setAmountToEnroll ] = useState<number | null>( product?.amountToEnroll || null );
	const [ price, setPrice ] = useState<number | null>( product?.price || null );
	const [ isDisabled, setIsDisabled ] = useState<boolean>( product?.isDisabled || false );
	const [ showLoading, setShowLoading ] = useState<boolean>( false );

	const onIsDisabledChange = () => setIsDisabled( !isDisabled );

	const isSaveButtonDisabled = !( marketId && title ) || showLoading;


	// ***************************************************************************************************
	// ***************************************************************************************************
	const onSave = async () =>
	{
		if( isSaveButtonDisabled )
		 	return;

		try
		{
			const newProduct: ProductType =
			{
				id: product?.id || 0,
				marketId,
				title,
				amountToEnroll,
				price,
				isDisabled
			};

			setShowLoading( true );

			await api.saveProduct( newProduct );
			props.onSave();
		}
		catch( error )
		{
			console.log( "Ошибка записи сервиса:", error );
			alert( "Ошибка записи сервиса! Попробуйте повторить попытку позже." );
		}
		finally
		{
			setShowLoading( false );
		}
	};


	// ***************************************************************************************************
	// ***************************************************************************************************
	return (
		<Modal show={ true } onHide={ onClose }>
			<Modal.Header closeButton>
				<Modal.Title>Редактирование сервиса</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group>
						<Form.Label>Идентификатор продукта в магазине</Form.Label>
						<FormControlString placeholder="Идентификатор продукта в магазине" value={ marketId } onChange={ setMarketId } />
					</Form.Group>
					<Form.Group>
						<Form.Label>Наименование</Form.Label>
						<FormControlString placeholder="Наименование" value={ title } onChange={ setTitle } />
					</Form.Group>
					<Form.Group>
						<Form.Label>Сумма к начислению</Form.Label>
						<FormControlNumber placeholder="Сумма к начислению" value={ amountToEnroll } onChange={ setAmountToEnroll } />
					</Form.Group>
					<Form.Group>
						<Form.Label>Цена</Form.Label>
						<FormControlNumber placeholder="Цена" value={ price } onChange={ setPrice } />
					</Form.Group>
					<Form.Group>
						<Form.Label>Отключено</Form.Label>
						<Form.Check type="checkbox" checked={ isDisabled } onChange={ onIsDisabledChange } />
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={ onClose }>
					Отмена
				</Button>
				<Button variant="primary" onClick={ onSave } disabled={ isSaveButtonDisabled }>
					Записать
					{ showLoading && <Spinner animation="border" variant="warning" /> }
				</Button>
			</Modal.Footer>
		</Modal>
	);

}
