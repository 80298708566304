import React, { useState } from "react";
import { Modal, Button, Form, ListGroup, Row, Alert } from "react-bootstrap";
import * as api from "../../api/api";



// ***************************************************************************************************
// ***************************************************************************************************
export function ImportPriceModal( props: any )
{
	const { onClose } = props;
	const [ errors, setErrors ] = useState<string[]>( [] );
	const [ importSuccessful, setImportSuccessful ] = useState<boolean>( false );


	// ***************************************************************************************************
	// ***************************************************************************************************
	const onFileSelect = async ( event: any ) =>
	{

		try
		{
			const formData = new FormData();
			formData.append( "file", event.target.files[ 0 ] );

			const result = await api.importPricelist( formData );

			// const params =
			// {
			// 	method: "POST",
			// 	body: formData
			// };

			// const response = await fetch( DOMAIN + "/admin/import_pricelist", params );

			// if ( response.status !== 200 )
			// {
			// 	const message = "При отправке прайса сервер вернул ошибку";
			// 	setErrors( [ message ] );
			// 	return;
			// }

			// const result: any = await response.json();

			if ( result.errors )
			{
				setErrors( result.errors );
				return;
			}

			setImportSuccessful( true );
		}
		catch ( error )
		{
			const message = "Ошибка отправки прайса";
			setErrors( [ message ] );
		}
	};


	const showFileInput = !importSuccessful && !errors.length;


	return (
		<Modal show={ true } onHide={ onClose }>
			<Modal.Header closeButton>
				<Modal.Title>Импорт прайса</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{
					importSuccessful &&
					<Alert variant="success">
						Прайс успешно импортирован
					</Alert>
				}
				{
					showFileInput &&
					<Form>
						<Form.Group controlId="formFile" className="mb-3">
							<Form.Label>Выберите файл прайса</Form.Label>
							<Form.Control type="file" onChange={ onFileSelect } />
						</Form.Group>
					</Form>
				}
				<ErrorsList errors={ errors } />
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={ onClose }>
					Закрыть
				</Button>
			</Modal.Footer>
		</Modal>
	);

}



function ErrorsList( props: any )
{
	const { errors } = props;

	if ( !errors || !errors.length )
		return null;

	let errorsText = "";

	for ( let error of errors )
		errorsText += error + "\n";

	return (
		<Row>
			<Alert variant="danger">
				Обнаружены ошибки во время импорта прайса.<br />
				Ни одна строка не импортирована.
			</Alert>

			Ошибки:
			<Form.Control
				as="textarea"
				style={ { height: '100px' } }
				value={ errorsText }
			/>
		</Row>
	);
}
