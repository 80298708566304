// import { DateRangePicker } from 'react-date-range';
import setDate from "date-fns/set";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Col, Container, Form, Nav, Row, Button, Table, Tabs, Tab, Breadcrumb } from "react-bootstrap";
import React, { forwardRef } from "react";


interface DateRangePropsType
{
	dateFrom: Date | null,
	dateTo: Date | null,
	onDateFromChange: ( date: Date ) => void,
	onDateToChange: ( date: Date ) => void,
}


// ***************************************************************************************************
// ***************************************************************************************************
export function DateRange( props: DateRangePropsType )
{
	const { dateFrom, dateTo } = props;

	const onDateFromChange = ( date: Date ) =>
	{
		const newDateFrom = startOfDay( date );
		props.onDateFromChange( newDateFrom );

		if( dateTo && newDateFrom.getTime() > dateTo.getTime() )
		{
			const newDateTo = endOfDay( date );
			props.onDateToChange( newDateTo );
		}
	};

	const onDateToChange = ( date: Date ) =>
	{
		const newDateTo = endOfDay( date );
		props.onDateToChange( newDateTo );

		if( dateFrom && newDateTo.getTime() < dateFrom.getTime() )
		{
			const newDateFrom = startOfDay( date );
			props.onDateFromChange( newDateFrom );
		}
	};


	return (
		<>
			<Col>
				<DatePicker
					selected={ dateFrom }
					onChange={ onDateFromChange }
					customInput={ <BootstrapInput label="Дата с" /> } />
			</Col>

			<Col>
				<DatePicker
					selected={ dateTo }
					onChange={ onDateToChange }
					customInput={ <BootstrapInput label="по" /> } />
			</Col>
		</>
	);
}



// ***************************************************************************************************
// ***************************************************************************************************
const BootstrapInput = forwardRef( ( props: any, ref: any ) => (
			<Form.Group>
				<Form.Label>{ props.label }</Form.Label>
				<Form.Control
					type="text"
					value={ props.value }
					onClick={ props.onClick }
					ref={ ref } />
			</Form.Group>
) );
