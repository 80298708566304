import "bootstrap/dist/css/bootstrap.min.css";
import debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as api from "../../api/api";
import { CountryEntryType } from "../../types/CountryEntryType";
import { CountryEditModal } from "./CountryEditModal/CountryEditModal";


// ***************************************************************************************************
	// ***************************************************************************************************
export function Countries()
{
	const [ countries, setCountries ] = useState<CountryEntryType[]>( [] );
	const [ allCountries, setAllCountries ] = useState<CountryEntryType[]>( [] );
	const [ searchString, setSearchString ] = useState<string>( "" );
	const [ countryToEdit, setCountryToEdit ] = useState<CountryEntryType | null | undefined>( undefined );

	const doSearch = debounce( () => findItem(), 300 );
	useEffect( () => { updateCountries() }, [] );
	useEffect( () => { doSearch() }, [ searchString ] );


	// ***************************************************************************************************
	// ***************************************************************************************************
	const updateCountries = async () =>
	{
		const countriesList = await api.getCountriesList();
		setAllCountries( countriesList );
		setCountries( countriesList );
	}


	// ***************************************************************************************************
	// ***************************************************************************************************
	const findItem = async () =>
	{
		if( !searchString )
		{
			if( countries.length !== allCountries.length )
				setCountries( allCountries );

			return;
		}

		const searchStringLowerCase = searchString.toLowerCase();
		const filteredCountries = allCountries.filter( ( element ) => element.title.toLowerCase().includes( searchStringLowerCase ) );

		setCountries( filteredCountries );
	};


	// ***************************************************************************************************
	// ***************************************************************************************************
	const onSearchStringUpdate = ( event: any ) => setSearchString( event.target.value );
	const onSearchStringKeyPress = ( event: any ) =>
		{
			if (event.key === "Enter")
				event.preventDefault();
		}


	// ***************************************************************************************************
	// ***************************************************************************************************
	const addCountry = () => setCountryToEdit( null );
	const onCountryEditModalClose = () => setCountryToEdit( undefined );
	const onCountryEditModalSave = () =>
	{
		updateCountries();
		onCountryEditModalClose();
	};
	const deleteCountry = async ( country: CountryEntryType, event: any ) =>
	{
		event.stopPropagation();

		/* eslint-disable no-restricted-globals */
		const result = confirm( "Вы уверены, что хотите удалить эту запись?" );

		if( !result )
			return;

		await api.deleteCountry( country.id );
		updateCountries();
	}



	// ***************************************************************************************************
	// ***************************************************************************************************
	return (
		<Container>
			{
				countryToEdit !== undefined &&
					<CountryEditModal country={ countryToEdit } onClose={ onCountryEditModalClose } onSave={ onCountryEditModalSave } />
			}
			<Breadcrumb>
				<Breadcrumb.Item><Link to="/">Главная</Link></Breadcrumb.Item>
				<Breadcrumb.Item active>Страны</Breadcrumb.Item>
			</Breadcrumb>

			<Form>
				<Row>
					<Form.Group as={ Col }>
						<Form.Label>Поиск</Form.Label>
						<Form.Control
							type="text"
							style={{width:"50%"}}
							value={ searchString }
							onChange={ onSearchStringUpdate }
							onKeyPress={ onSearchStringKeyPress } />
					</Form.Group>
				</Row>
			</Form>

			<Table striped bordered hover className="marginTop">
				<thead>
					<tr>
						<th>ID</th>
						<th>Наименование</th>
						<th>SMS-Activate</th>
						<th>5sim</th>
						<th>onlineSIM</th>
						<th>Иконка</th>
						<th><Button variant="primary" onClick={ addCountry }>Добавить</Button></th>
					</tr>
				</thead>
				<tbody>
					{
						countries.map( ( country ) =>
							<tr key={ country.id } onClick={ () => setCountryToEdit( country ) }>
								<td>{ country.id }</td>
								<td>{ country.title }</td>
								<td>{ country.smsActivateCode }</td>
								<td>{ country.fiveSimCode }</td>
								<td>{ country.onlineSimCode }</td>
								<td><Icon file={ country.icon } /></td>
								<td><Button variant="danger" onClick={ ( event ) => deleteCountry( country, event ) }>Удалить</Button></td>
							</tr>
						)
					}
				</tbody>
			</Table>
		</Container>
	);
}


// ***************************************************************************************************
// ***************************************************************************************************
function Icon( props: any )
{
	const file = props.file;

	if( !file )
		return null;

	return <img src={ "/icon/countries/" + file } height="20" alt="Иконка" />
}
