import format from "date-fns/format";
import parseISO from "date-fns/parseISO";


// ***************************************************************************************************
// ***************************************************************************************************
export function getFormattedDateTime( dateTimeString: string ): string
{
	const dateTime = parseISO( dateTimeString );

	return format( dateTime, "dd.MM.yyyy HH:mm OOOO" );
}
