import { PriceEntryType } from "../types/PriceEntryType";
import { CountryEntryType } from "../types/CountryEntryType";
import { ServiceEntryType } from "../types/ServiceEntryType";
import { ProductType } from "../types/ProductType";
import { OrderStatuses } from "../types/OrderStatuses";
import { ProviderType } from "src/types/ProviderType";
import { RefundType } from "src/types/RefundType";


let jwt = isDebug() ? process.env.REACT_APP_TOKEN : "";



// ***************************************************************************************************
// ***************************************************************************************************
export function isJwtSet(): boolean
{
	return !!jwt;
}



// ***************************************************************************************************
// ***************************************************************************************************
export function setJwt( token: string )
{
	jwt = token;
}



// ***************************************************************************************************
// ***************************************************************************************************
export function isDebug(): boolean
{
	// return false;
	return process.env.NODE_ENV !== "production";
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function login( authCode: string ): Promise<string | null>
{
	const result = await fetchData( "/api/auth", { authCode } );

	const jwt = result.jwt;

	if( jwt )
	{
		setJwt( jwt );
		return jwt;
	}

	return null;
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function getUsersCount()
{
	return fetchData( "/admin_api/get_users_count", {} );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function getServicesBalance()
{
	return fetchData( "/admin_api/get_services_balance" );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function getUsersList()
{
	return fetchData( "/admin_api/get_users", {} );
}


// ***************************************************************************************************
// ***************************************************************************************************
interface FindUserFilterType
{
	id?: number | null,
	emailPart?: string | null
}

export async function findUsers( filter: FindUserFilterType )
{
	return fetchData( "/admin_api/find_users", { ...filter } );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function getUser( id: number )
{
	return fetchData( "/admin_api/get_user", { id } );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function toggleUserBlock( id: number )
{
	return fetchData( "/admin_api/toggle_user_block", { id } );
}


// ***************************************************************************************************
// ***************************************************************************************************
interface OrderFilterType
{
	userId: number | null,
	phone: string | null,
	status: OrderStatuses | null,
	dateFromTimestamp: number | null,
	dateToTimestamp: number | null
}

export async function getOrders( filter: OrderFilterType )
{
	return fetchData( "/admin_api/get_orders", { ...filter } );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function getLogsByOrderId( id: number )
{
	return fetchData( "/admin_api/get_logs_by_order", { id } );
}



// ***************************************************************************************************
// ***************************************************************************************************
export async function getServicesList()
{
	return fetchData( "/admin_api/get_services_list", {} );
}



// ***************************************************************************************************
// ***************************************************************************************************
export async function saveService( service: ServiceEntryType )
{
	return fetchData( "/admin_api/save_service", { service } );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function deleteService( id: number )
{
	return fetchData( "/admin_api/delete_service", { id } );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function getCountriesList()
{
	return fetchData( "/admin_api/get_countries_list", {} );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function saveCountry( country: CountryEntryType )
{
	return fetchData( "/admin_api/save_country", { country } );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function deleteCountry( id: number )
{
	return fetchData( "/admin_api/delete_country", { id } );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function getPricelist( countryId: number | undefined, serviceId: number | undefined )
{
	if( !countryId && !serviceId )
		return [];

	return fetchData( "/admin_api/get_pricelist", { countryId, serviceId } );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function savePricelist( pricelist: PriceEntryType )
{
	return fetchData( "/admin_api/save_pricelist", { pricelist } );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function deletePricelistEntry( pricelistId: number )
{
	return fetchData( "/admin_api/delete_pricelist", { pricelistId } );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function importPricelist( formData: FormData )
{
	return fetchData( "/admin_api/import_pricelist", formData );
}




// ***************************************************************************************************
// ***************************************************************************************************
interface TransactionsFilterType
{
	userId: number | null,
	sourceId: number | null,
	sourceTransactionId: string | null,
	dateFromTimestamp: number | null,
	dateToTimestamp: number | null
}

export async function getTransactions( filter: TransactionsFilterType )
{
	return fetchData( "/admin_api/get_transactions", { ...filter } );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function getUserStatistics( userId: number )
{
	return fetchData( "/admin_api/get_user_statistics", { id: userId } );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function updateBalanceByAdmin( userId: number, amount: number )
{
	return fetchData( "/admin_api/update_balance_by_admin", { id: userId, amount } );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function getConfig()
{
	return fetchData( "/admin_api/get_config" );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function updateConfigItem( configId: string, value: string )
{
	return fetchData( "/admin_api/set_config_item", { id: configId, value } );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function getProductsList()
{
	return fetchData( "/admin_api/get_products_list", {} );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function saveProduct( product: ProductType )
{
	return fetchData( "/admin_api/save_product", { product } );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function toggleDisabled( id: number )
{
	return fetchData( "/admin_api/toggle_product_disabled", { id } );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function getProvidersList(): Promise<ProviderType[]>
{
	return fetchData( "/admin_api/providers/get_list", {} );
}


// ***************************************************************************************************
// ***************************************************************************************************
export async function updateProvidersPriority( providers: ProviderType[] )
{
	return fetchData( "/admin_api/providers/update_priority", { providers } );
}



// ***************************************************************************************************
// ***************************************************************************************************
export async function getRefunds(): Promise<RefundType[]>
{
	const result = await fetchData( "/admin_api/get_google_refunds", {} );

	return result;
}



// ***************************************************************************************************
// ***************************************************************************************************
const fetchData = async ( url: string, payload?: object ): Promise<any> =>
{
	const params =
	{
		method: "POST",
		headers: {},
		body:
			payload instanceof FormData ?
				payload :
				JSON.stringify( payload || {} )
	};

	if( !( payload instanceof FormData ) )
		params.headers[ "Content-Type" ] = "application/json";

	if( isJwtSet() )
		params.headers[ "Authorization" ] = `Bearer ${ jwt }`;

	const response = await fetch( url, params );
	const result: any = response.json();

	if( response.status !== 200 )
		return Promise.reject( result );

	return Promise.resolve( result );
};
