import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Row, Table } from "react-bootstrap";
import * as api from "../api/api";
import { ConfigType, INDYNUMBER_SERVICE_COST_PREFIX } from "../api/types/ConfigType";
import { EditConfigItemModal } from "./EditConfigItemModal/EditConfigItemModal";



export function Config()
{
	const [ config, setConfig ] = useState<ConfigType[]>( [] );
	const [ configItemToEdit, setConfigItemToEdit ] = useState<ConfigType | null>( null );

	useEffect( () => { updateConfigList() }, [] );

	const updateConfigList = async () =>
	{
		const configItemsList = await api.getConfig();
		setConfig( configItemsList );
	};

	const onCloseConfigItemEditModal = () => setConfigItemToEdit( null );

	const onSaveConfigItem = async ( configItem: ConfigType ) =>
	{
		await api.updateConfigItem( configItem.id, configItem.value );
		updateConfigList();
		onCloseConfigItemEditModal();
	};


	// ***************************************************************************************************
	// ***************************************************************************************************
	return (
		<Row>
			<Table striped bordered hover>
				<thead>
					<tr>
						<th>Настройка</th>
						<th>Значение</th>
					</tr>
				</thead>
				<tbody>
					{
						config.map( ( configItem ) =>
							<tr key={ configItem.id } onClick={ () => setConfigItemToEdit( configItem ) }>
								<td>{ configItem.title }</td>
								<td>{ configItem.value }</td>
							</tr>
						)
					}
				</tbody>
			</Table>
			{
				configItemToEdit &&
					<EditConfigItemModal config={ configItemToEdit } onClose={ onCloseConfigItemEditModal } onSave={ onSaveConfigItem } />
			}
		</Row>
	);
}



function getConfigItemTitle( configItem: ConfigType )
{
	if ( !configItem.title.startsWith( INDYNUMBER_SERVICE_COST_PREFIX ) )
		return configItem.title;
}

